import { defaultPitch } from './pitch'
import { demonstrationEventTypes } from './events'
import { pitchSetupTypes } from '../../pitches/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'
export const demonstrationProps = {
  eventTypes: demonstrationEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    pitchPointCount: 4,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    enableBench: true
  },
  features: {
    targetEvents: [],
    positionNumbers: false,
    maxPlayers: 50,
    defaultUnitSystem: 'SI',
    keyboardShortcuts: {},
    recentEventTableMetrics: [],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {}
  } as const
}
