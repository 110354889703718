import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './Drills.module.scss'
import { DrillControls } from '../../../metrics_server/drills/components/DrillControls/DrillControls'
import { DrillTimeLine } from '../../../metrics_server/drills/components/DrillTimeLine/DrillTimeLine'
import { DrillsMetrics } from '../../../metrics_server/drills/components/DrillsMetrics/DrillsMetrics'
import { CardList } from '../../../components/CardList/CardList'
import { DrillsList } from '../../../metrics_server/drills/components/DrillsList/DrillsList'
import { Toggle } from '../../../components/Forms/Fields/Toggle/Toggle'
import { usePrevious } from 'use-hooks'
import { selectDrill } from '../../../metrics_server/drills/slice'
import { useAppDispatch } from '../../../store/hooks'
import {
  useDrills,
  useSelectedFormattedDrill
} from '../../../metrics_server/drills/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { getPlayerBreakdownForDrill } from '../../../metrics_server/drills/thunks'
import { MqttContext } from '../../../metrics_server/mqtt/Provider'
import { useSportscaster } from '../../../metrics_server/sportscaster/hooks'
import { getSelectedBroker } from '../../../metrics_server/sportscaster/functions'

export const Drills = ({
  canvasId,
  active,
  hiddenCanvases,
  toggleCanvasVisibility,
  strack,
  strackReady
}) => {
  const dispatch = useAppDispatch()

  const { drillCount, selectedId } = useDrills()
  const formattedSession = useSelectedFormattedSession()
  const sportscaster = useSportscaster()
  const unitSystem = useUnitsSystem(formattedSession.sport)

  const [view, setView] = useState('metrics')
  const previousView = usePrevious(view)

  const selectedDrill = useSelectedFormattedDrill()

  // Set strack drill based on selected drill //
  useEffect(() => {
    if (strackReady) {
      strack.selectDrill(selectedDrill)
    }
  }, [selectedDrill, strackReady])

  // Manage Player Breakdowns //

  const mqttContext = useContext(MqttContext)
  const drillTopic = useRef(null)

  useEffect(() => {
    if (!selectedId) return

    // Fetch player metrics for selected drill
    dispatch(
      getPlayerBreakdownForDrill({
        drillId: selectedId,
        unitSystemValue: unitSystem.key
      })
    )

    // Get selected broker client
    const selectedBroker = getSelectedBroker(sportscaster)
    const client = selectedBroker ? mqttContext[selectedBroker.WSSHost] : null

    // Disconnect from previous drill mqtt topic
    if (client && drillTopic.current) {
      client.unsubscribe(drillTopic.current)
    }

    // Create and save new drill mqtt topic
    const newDrillTopic = `drill/${selectedId}/events`
    drillTopic.current = newDrillTopic

    // Connect to drill mqtt topic
    if (client) {
      client.subscribe(newDrillTopic, (data) => {
        console.log(data, '==============drill event======================')
        // Fetch player metrics for selected drill
        dispatch(
          getPlayerBreakdownForDrill({
            drillId: selectedId,
            unitSystemValue: unitSystem.key
          })
        )
      })
    }

    return () => {
      // Disconnect from drill mqtt topic
      if (client) {
        client.unsubscribe(drillTopic.current)
      }
    }
  }, [unitSystem, selectedId, sportscaster.selectedBrokerHost])

  // Toggle canvas visibility based on the selected view
  useEffect(() => {
    if (view === 'pitch' && previousView !== 'pitch') {
      toggleCanvasVisibility(canvasId)
    }

    if (previousView === 'pitch' && view !== 'pitch') {
      toggleCanvasVisibility(canvasId)
    }
  }, [view, hiddenCanvases, toggleCanvasVisibility])

  // On mount //
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        dispatch(selectDrill(null))
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      dispatch(selectDrill(null))
    }
  }, [])

  // Don't render anything if the dill tab is not active
  if (!active) {
    return null
  }

  if (drillCount === 0 && !formattedSession.live) {
    return <div className={styles.noDrillsContainer}>No Drills Recorded</div>
  }

  return (
    <div className={styles.drillsContainer}>
      {/* Drills timeline and controls container */}
      <div className={styles.drillsTimeContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={`${styles.drillsTimeWrapper}`}>
            <DrillControls />
            <DrillTimeLine />
          </div>
        </CardList>
      </div>

      {/* Drills Pitch handled by strack property in session config and toggle canvas visibility */}

      {/* Drills metrics container */}
      {drillCount !== 0 && view === 'metrics' && (
        <div className={styles.drillsMetricsContainer}>
          <CardList
            col={12}
            items={[{}]}
            scrollerId={`scroller-${1}`}
            className='maxHeight'
          >
            <DrillsMetrics />
          </CardList>
        </div>
      )}

      {/* Pitch player toggle */}
      <div className={styles.metricPitchToggleContainer}>
        <Toggle
          input={{
            value: view,
            onChange: (value) => setView(value)
          }}
          options={[
            { name: 'Metrics', value: 'metrics' },
            { name: 'Pitch', value: 'pitch' }
            // { name: 'Events', value: 2 }
          ]}
          isSkillTracker={false}
          size={'s'}
        />
      </div>

      {/* Drills list container */}
      <div className={styles.drillsListContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <DrillsList />
        </CardList>
      </div>
    </div>
  )
}
