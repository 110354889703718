import { defaultPitch } from './pitch'
import { soccerEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'
import { sportableColors } from '../../../constants/sportableColors'

export const soccerProps = {
  eventTypes: soccerEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    pitchPointCount: 34,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    setupTypes: pitchSetupTypes,
    enableRotate: true,
    enableBench: true,
    defaultDrillRegion: {
      P1: 'P10',
      P2: 'P8',
      P3: 'P3',
      P4: 'P1',
      P5: 'P16',
      P6: 'P33',
      P7: 'P15',
      P8: 'P34'
    } as const,
    drillsGoalsOrPosts: {
      enabled: true,
      dimensions: {
        large: {
          width: 7.32,
          height: 2.44,
          depth: 2.44
        },
        small: {
          width: 1.914,
          height: 1.914,
          depth: 1.61
        }
      }
    },
    drillSnapLines: ['P1', 'P2', 'P3']
  },
  features: {
    drillsEnabled: true,
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 18,
    defaultUnitSystem: 'British',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        a: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.complete?.value
        },
        x: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.incomplete?.value
        },
        i: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.soccerPass?.value
            )
          },
          outcome: outcomeTypes.items.interception?.value
        }
      }
    },
    recentEventTableMetrics: ['dist', 'hangTime', 'speed'],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {
      '1': {
        name: 'No Bib',
        colour: null
      },
      '2': {
        name: 'Red',
        colour: sportableColors.colors.sportableRed
      },
      '3': {
        name: 'Blue',
        colour: sportableColors.colors.sportableBlue
      },
      '4': {
        name: 'Green',
        colour: sportableColors.colors.sportableGreen
      },
      '5': {
        name: 'Orange',
        colour: sportableColors.colors.sportableOrange
      }
    },
    targetMetrics: ['dist', 'hangTime']
  } as const
}
