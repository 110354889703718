import { defaultPitch } from './pitch'
import { americanFootballEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'
import { sportableColors } from '../../../constants/sportableColors'

export const americanFootballProps = {
  eventTypes: americanFootballEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    pitchPointCount: 30,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    tenYardMarkings: 10,
    enableBench: true,
    enableRotate: true,
    defaultDrillRegion: {
      P1: 'P26',
      P2: 'P14',
      P3: 'P13',
      P4: 'P1',
      P5: null,
      P6: null,
      P7: null,
      P8: null
    } as const,
    drillsGoalsOrPosts: {
      enabled: false
    },
    drillSnapLines: [
      'P1',
      'P2',
      'P3',
      'P4',
      'P5',
      'P6',
      'P7',
      'P8',
      'P9',
      'P10',
      'P11',
      'P12',
      'P13'
    ]
  },
  features: {
    drillsEnabled: true,
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 46,
    defaultUnitSystem: 'American',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        // c: {
        //   condition: (
        //     formattedEvent: FormattedEventData,
        //     flightTypes: FlightEventTypeGroup
        //   ) => {
        //     return (
        //       formattedEvent &&
        //       formattedEvent.type.selected?.value ===
        //         flightTypes.items.kick?.value
        //     )
        //   },
        //   outcome: outcomeTypes.items.lost?.value
        // },
        x: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.incomplete?.value
        },
        // t: {
        //   condition: (
        //     formattedEvent: FormattedEventData,
        //     flightTypes: FlightEventTypeGroup
        //   ) => {
        //     return (
        //       formattedEvent &&
        //       formattedEvent.type.selected?.value ===
        //         flightTypes.items.pass?.value
        //     )
        //   },
        //   outcome: outcomeTypes.items.touchdown?.value
        // },
        i: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value
            )
          },
          outcome: outcomeTypes.items.interception?.value
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {
      '1': {
        name: 'No Bib',
        colour: null
      },
      '2': {
        name: 'Offense',
        colour: sportableColors.colors.sportableRed
      },
      '3': {
        name: 'Defense',
        colour: sportableColors.colors.sportableBlue
      }
    }
  } as const
}
