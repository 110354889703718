import React from 'react'
import { PlayerData } from '../../metrics_server/players/types'
import { AggregatedFormattedFlightData } from '../../metrics_server/events/flight/types'
import { Table } from '../Table/Table'
import { MetricTypeGroup } from '../../metrics_server/metrics/data_types'
export type PlayerPassData = {
  id: string
  player: PlayerData
  count: number
  metrics: AggregatedFormattedFlightData
}
interface PlayerPassTableProps {
  items: PlayerPassData[]
  passMetricTypes: MetricTypeGroup
}
export const PlayerPassTable: React.FC<PlayerPassTableProps> = ({
  items,
  passMetricTypes
}) => {
  const tableHeaders = [
    { name: 'Player', key: 'playerName', width: 20 },
    { name: 'Passes', key: 'passCount', width: 10 },
    {
      name: 'Avg ' + passMetricTypes.items.speed?.name,
      key: 'avgSpeed',
      width: 15
    },
    {
      name: 'Avg ' + passMetricTypes.items.initialVelocity?.name,
      key: 'avgInitialVelocity',
      width: 15
    },
    {
      name: 'Max ' + passMetricTypes.items.speed?.name,
      key: 'maxSpeed',
      width: 15
    },
    {
      name: 'Total ' + passMetricTypes.items.territorialGain?.name,
      key: 'territorialGain',
      width: 15
    },
    {
      name: 'Avg ' + passMetricTypes.items.reloadTime?.name,
      key: 'avgReleaseTime',
      width: 15
    },
    {
      name: 'Avg ' + passMetricTypes.items.hangTime?.name,
      key: 'avgHangTime',
      width: 15
    }
  ]

  const tableData = items.map((item) => ({
    id: item.id,
    playerName: item.player.number
      ? `${item.player.firstName} ${item.player.lastName} - ${item.player.number}`
      : `${item.player.firstName} ${item.player.lastName}`,
    passCount: item.count,
    avgSpeed: item.metrics.speed.Mean.formattedValue,
    avgInitialVelocity: item.metrics.initialVelocity.Mean.formattedValue,
    maxSpeed: item.metrics.speed.Maximum.formattedValue,
    territorialGain: item.metrics.territorialGain
      ? item.metrics.territorialGain.Sum.formattedValue
      : '-',
    avgReleaseTime: item.metrics.reloadTime
      ? item.metrics.reloadTime.Mean.formattedValue
      : '-',
    avgHangTime: item.metrics.hangTime.Mean.formattedValue
  }))

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box'
      }}
    >
      <Table
        options={{
          initialOrder: 'dec',
          initialSortBy: 'passCount',
          sortActive: true
        }}
        id='playerPassTable'
        searchBy={['playerName', 'playerNumber']}
        headerFont={13}
        smallHead={true}
        // tableSize='large'
        tableClass={'minimalistBlack'}
        className={'container'}
        data={tableData}
        headers={tableHeaders}
      />
    </div>
  )
}
