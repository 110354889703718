import { PitchTypes, RawPitchData } from '../../metrics_server/pitches/types'
import { Sport, SportTypeValues } from '../../metrics_server/sports/data_types'
import { Dimensions } from './types'

// Extract second pitch dimensions //
export function extractSecondPitchDimensions(
  sport: Sport,
  dimensions: Dimensions
) {
  const secondPitchDimensions = {}

  let secondPitchPointCount = 0
  for (const key in dimensions) {
    const dimension = dimensions[key]
    const pNumber = parseInt(key.split('P')[1])
    if (pNumber > sport.props.pitch.pitchPointCount) {
      secondPitchPointCount++
      secondPitchDimensions[`P${pNumber - sport.props.pitch.pitchPointCount}`] =
        {
          x: dimension.x,
          y: dimension.y,
          z: dimension.z
        }
    }
  }

  if (secondPitchPointCount !== sport.props.pitch.pitchPointCount) {
    // console.log('Second pitch does not have the expected number of points')
    return null
  }
  return secondPitchDimensions
}

// Pitch and anchor
export const getStrackPitchAndAnchorConfig = (
  pitch: PitchTypes | RawPitchData,
  sportType?: SportTypeValues
) => {
  const config = {
    anchorConfig: {},
    pitch: {
      type: sportType,
      coordinates: null,
      arcs: null
    }
  }

  if (pitch && pitch.pitch) {
    if (sportType && pitch.type !== sportType) {
      config.pitch = {
        type: sportType,
        coordinates: null,
        arcs: null
      }
      config.anchorConfig = {}
    } else {
      config.pitch = {
        type: pitch.type,
        coordinates: pitch.pitch.coordinates,
        arcs: pitch.arcs
      }
      if (pitch.anchors) {
        for (const key in pitch.anchors) {
          config.anchorConfig[key] = pitch.anchors[key]
        }
      }
    }
  }
  return config
}
