import { SportTypeKey } from '../data_types'
import { sportTypeKey } from '../'

import {
  Coordinate,
  Pitch,
  PitchCoordinates,
  PitchDimensions
} from '../../pitches/types'

export interface SoccerPitch extends Pitch {
  type: SportTypeKey['soccer']
  pitch: SoccerPitchDimensions
}

export interface SoccerPitchDimensions extends PitchDimensions {
  length: number
  width: number
  posts: {
    width: number
    height: number
    crossbarHeight: number
    diameter: number
  }
  coordinates: SoccerPitchCoordinates
}

export interface SoccerPitchCoordinates extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
  P5: Coordinate
  P6: Coordinate
  P7: Coordinate
  P8: Coordinate
  P9: Coordinate
  P10: Coordinate
  P11: Coordinate
  P12: Coordinate
  P13: Coordinate
  P14: Coordinate
  P15: Coordinate
  P16: Coordinate
  P17: Coordinate
  P18: Coordinate
  P19: Coordinate
  P20: Coordinate
  P21: Coordinate
  P22: Coordinate
  P23: Coordinate
  P24: Coordinate
  P25: Coordinate
  P26: Coordinate
  P27: Coordinate
  P28: Coordinate
  P29: Coordinate
  P30: Coordinate
  P31: Coordinate
  P32: Coordinate
  P33: Coordinate
  P34: Coordinate
}

const coordinates = {
  P1: {
    x: -50.0,
    y: 0.0,
    z: 0.0
  },
  P2: {
    x: 0.0,
    y: 0.0,
    z: 0.0
  },
  P3: {
    x: 50.0,
    y: 0.0,
    z: 0.0
  },
  P4: {
    x: 50.0,
    y: 14.9,
    z: 0.0
  },
  P5: {
    x: 50.0,
    y: 25.85,
    z: 0.0
  },
  P6: {
    x: 50.0,
    y: 44.15,
    z: 0.0
  },
  P7: {
    x: 50.0,
    y: 55.1,
    z: 0.0
  },
  P8: {
    x: 50.0,
    y: 70.0,
    z: 0.0
  },
  P9: {
    x: 0.0,
    y: 70.0,
    z: 0.0
  },
  P10: {
    x: -50.0,
    y: 70.0,
    z: 0.0
  },
  P11: {
    x: -50.0,
    y: 55.1,
    z: 0.0
  },
  P12: {
    x: -50.0,
    y: 44.15,
    z: 0.0
  },
  P13: {
    x: -50.0,
    y: 25.85,
    z: 0.0
  },
  P14: {
    x: -50.0,
    y: 14.9,
    z: 0.0
  },
  P15: {
    x: -50.0,
    y: 31.34,
    z: 2.44
  },
  P16: {
    x: -50.0,
    y: 38.66,
    z: 2.44
  },
  P17: {
    x: -44.5,
    y: 25.85,
    z: 0.0
  },
  P18: {
    x: -44.5,
    y: 44.15,
    z: 0.0
  },
  P19: {
    x: -39.0,
    y: 35.0,
    z: 0.0
  },
  P20: {
    x: -33.5,
    y: 14.9,
    z: 0.0
  },
  P21: {
    x: -33.5,
    y: 27.69,
    z: 0.0
  },
  P22: {
    x: -33.5,
    y: 42.31,
    z: 0.0
  },
  P23: {
    x: -33.5,
    y: 55.1,
    z: 0.0
  },
  P24: {
    x: -9.15,
    y: 35.0,
    z: 0.0
  },
  P25: {
    x: 0.0,
    y: 35.0,
    z: 0.0
  },
  P26: {
    x: 33.5,
    y: 55.1,
    z: 0.0
  },
  P27: {
    x: 33.5,
    y: 42.31,
    z: 0.0
  },
  P28: {
    x: 33.5,
    y: 27.69,
    z: 0.0
  },
  P29: {
    x: 33.5,
    y: 14.9,
    z: 0.0
  },
  P30: {
    x: 39.0,
    y: 35.0,
    z: 0.0
  },
  P31: {
    x: 44.5,
    y: 44.15,
    z: 0.0
  },
  P32: {
    x: 44.5,
    y: 25.85,
    z: 0.0
  },
  P33: {
    x: 50.0,
    y: 38.66,
    z: 2.44
  },
  P34: {
    x: 50.0,
    y: 31.34,
    z: 2.44
  }
}

const doublePitchCoordinates = {
  P1: {
    x: -50.0,
    y: 0.0,
    z: 0.0
  },
  P2: {
    x: 0.0,
    y: 0.0,
    z: 0.0
  },
  P3: {
    x: 50.0,
    y: 0.0,
    z: 0.0
  },
  P4: {
    x: 50.0,
    y: 14.9,
    z: 0.0
  },
  P5: {
    x: 50.0,
    y: 25.85,
    z: 0.0
  },
  P6: {
    x: 50.0,
    y: 44.15,
    z: 0.0
  },
  P7: {
    x: 50.0,
    y: 55.1,
    z: 0.0
  },
  P8: {
    x: 50.0,
    y: 70.0,
    z: 0.0
  },
  P9: {
    x: 0.0,
    y: 70.0,
    z: 0.0
  },
  P10: {
    x: -50.0,
    y: 70.0,
    z: 0.0
  },
  P11: {
    x: -50.0,
    y: 55.1,
    z: 0.0
  },
  P12: {
    x: -50.0,
    y: 44.15,
    z: 0.0
  },
  P13: {
    x: -50.0,
    y: 25.85,
    z: 0.0
  },
  P14: {
    x: -50.0,
    y: 14.9,
    z: 0.0
  },
  P15: {
    x: -50.0,
    y: 31.34,
    z: 2.44
  },
  P16: {
    x: -50.0,
    y: 38.66,
    z: 2.44
  },
  P17: {
    x: -44.5,
    y: 25.85,
    z: 0.0
  },
  P18: {
    x: -44.5,
    y: 44.15,
    z: 0.0
  },
  P19: {
    x: -39.0,
    y: 35.0,
    z: 0.0
  },
  P20: {
    x: -33.5,
    y: 14.9,
    z: 0.0
  },
  P21: {
    x: -33.5,
    y: 27.69,
    z: 0.0
  },
  P22: {
    x: -33.5,
    y: 42.31,
    z: 0.0
  },
  P23: {
    x: -33.5,
    y: 55.1,
    z: 0.0
  },
  P24: {
    x: -9.15,
    y: 35.0,
    z: 0.0
  },
  P25: {
    x: 0.0,
    y: 35.0,
    z: 0.0
  },
  P26: {
    x: 33.5,
    y: 55.1,
    z: 0.0
  },
  P27: {
    x: 33.5,
    y: 42.31,
    z: 0.0
  },
  P28: {
    x: 33.5,
    y: 27.69,
    z: 0.0
  },
  P29: {
    x: 33.5,
    y: 14.9,
    z: 0.0
  },
  P30: {
    x: 39.0,
    y: 35.0,
    z: 0.0
  },
  P31: {
    x: 44.5,
    y: 44.15,
    z: 0.0
  },
  P32: {
    x: 44.5,
    y: 25.85,
    z: 0.0
  },
  P33: {
    x: 50.0,
    y: 38.66,
    z: 2.44
  },
  P34: {
    x: 50.0,
    y: 31.34,
    z: 2.44
  },

  P35: {
    x: -50.0,
    y: 75.0,
    z: 0.0
  },
  P36: {
    x: 0.0,
    y: 75.0,
    z: 0.0
  },
  P37: {
    x: 50.0,
    y: 75.0,
    z: 0.0
  },
  P38: {
    x: 50.0,
    y: 89.9,
    z: 0.0
  },
  P39: {
    x: 50.0,
    y: 100.85,
    z: 0.0
  },
  P40: {
    x: 50.0,
    y: 119.15,
    z: 0.0
  },
  P41: {
    x: 50.0,
    y: 130.1,
    z: 0.0
  },
  P42: {
    x: 50.0,
    y: 145.0,
    z: 0.0
  },
  P43: {
    x: 0.0,
    y: 145.0,
    z: 0.0
  },
  P44: {
    x: -50.0,
    y: 145.0,
    z: 0.0
  },
  P45: {
    x: -50.0,
    y: 130.1,
    z: 0.0
  },
  P46: {
    x: -50.0,
    y: 119.15,
    z: 0.0
  },
  P47: {
    x: -50.0,
    y: 100.85,
    z: 0.0
  },
  P48: {
    x: -50.0,
    y: 89.9,
    z: 0.0
  },
  P49: {
    x: -50.0,
    y: 106.34,
    z: 2.44
  },
  P50: {
    x: -50.0,
    y: 113.66,
    z: 2.44
  },
  P51: {
    x: -44.5,
    y: 100.85,
    z: 0.0
  },
  P52: {
    x: -44.5,
    y: 119.15,
    z: 0.0
  },
  P53: {
    x: -39.0,
    y: 110.0,
    z: 0.0
  },
  P54: {
    x: -33.5,
    y: 89.9,
    z: 0.0
  },
  P55: {
    x: -33.5,
    y: 102.69,
    z: 0.0
  },
  P56: {
    x: -33.5,
    y: 117.31,
    z: 0.0
  },
  P57: {
    x: -33.5,
    y: 130.1,
    z: 0.0
  },
  P58: {
    x: -9.15,
    y: 110.0,
    z: 0.0
  },
  P59: {
    x: 0.0,
    y: 110.0,
    z: 0.0
  },
  P60: {
    x: 33.5,
    y: 130.1,
    z: 0.0
  },
  P61: {
    x: 33.5,
    y: 117.31,
    z: 0.0
  },
  P62: {
    x: 33.5,
    y: 102.69,
    z: 0.0
  },
  P63: {
    x: 33.5,
    y: 89.9,
    z: 0.0
  },
  P64: {
    x: 39.0,
    y: 110.0,
    z: 0.0
  },
  P65: {
    x: 44.5,
    y: 119.15,
    z: 0.0
  },
  P66: {
    x: 44.5,
    y: 100.85,
    z: 0.0
  },
  P67: {
    x: 50.0,
    y: 113.66,
    z: 2.44
  },
  P68: {
    x: 50.0,
    y: 106.34,
    z: 2.44
  }
}

export const defaultPitch: SoccerPitch = {
  id: '0',
  name: 'Default Football Pitch',
  type: sportTypeKey.soccer,
  anchors: null,
  arcs: null,
  pitch: {
    length: 0,
    width: 0,
    posts: {
      width: 7.32,
      height: 2.44,
      crossbarHeight: 2.44,
      diameter: 0.12
    },
    coordinates: doublePitchCoordinates
  }
}
