import { Button } from '../../../../components/Button/Button'
import styles from './DrillControls.module.scss'
import {
  useActiveFormattedDrill,
  useDrills,
  useSelectedFormattedDrill
} from '../../hooks'
import { useAppDispatch } from '../../../../store/hooks'
import { startDrill, stopDrill, updateExistingDrill } from '../../thunks'
import { useSelectedFormattedSession } from '../../../sessions/hooks'
import { useEffect, useState } from 'react'
import BasicSelect from '../../../../components/Material/Select'
import { selectDrill } from '../../slice'

export const DrillControls = () => {
  const dispatch = useAppDispatch()

  const { drillCount } = useDrills()

  const formattedSession = useSelectedFormattedSession()

  const selectedFormattedDrill = useSelectedFormattedDrill()
  const activeFormattedDrill = useActiveFormattedDrill()

  // Handle drill name input //
  const [newDrillName, setNewDrillName] = useState(selectedFormattedDrill?.name)
  useEffect(() => {
    setNewDrillName(selectedFormattedDrill?.name)
  }, [selectedFormattedDrill])
  // ======================== //

  if (formattedSession.live && drillCount === 0) {
    return <div className={styles.noDrillsContainer}>Add A Drill</div>
  } else {
    return (
      <div className={styles.drillsControlsContainer}>
        <div className={styles.controlsMessageContainer}>
          {/* Drill name input */}
          {selectedFormattedDrill ? (
            <BasicSelect
              label={
                !selectedFormattedDrill?.id
                  ? 'No Drill Selected'
                  : 'Edit Drill Name'
              }
              variant='standard'
              selected={newDrillName}
              onChange={(value) => setNewDrillName(value)}
              labelShrink={true}
              size={'small'}
              onBlur={() => {
                if (selectedFormattedDrill) {
                  dispatch(
                    updateExistingDrill({
                      ...selectedFormattedDrill.rawData,
                      name: newDrillName,
                      sessionId: formattedSession.id
                    })
                  )
                }
              }}
            />
          ) : (
            <div style={{ textAlign: 'center' }}>No Drill Selected</div>
          )}

          {/* Drill finished */}
          {selectedFormattedDrill?.isFinished && (
            <div className={styles.drillIsRunningMessage}>Drill Finished</div>
          )}

          {/* Stop button */}
          {selectedFormattedDrill?.isActive && (
            <Button
              className='btn--border'
              handleClick={() =>
                dispatch(stopDrill(selectedFormattedDrill?.id))
              }
              style={{
                backgroundColor: '#ff0000',
                width: '100%',
                marginTop: '5px'
              }}
            >
              Stop Drill
            </Button>
          )}

          {/* Start button */}
          {selectedFormattedDrill?.isPending &&
            (activeFormattedDrill ? (
              <div className={styles.drillIsRunningMessage}>
                Stop active drill {' ('}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => dispatch(selectDrill(activeFormattedDrill.id))}
                >
                  {activeFormattedDrill.name}
                </span>
                {') '}
                before starting another
              </div>
            ) : (
              <Button
                className='btn--border'
                handleClick={() =>
                  dispatch(startDrill(selectedFormattedDrill.id))
                }
                style={{
                  backgroundColor: '#00ff00',
                  width: '100%',
                  marginTop: '5px'
                }}
                disabled={!!activeFormattedDrill}
              >
                Start Drill
              </Button>
            ))}
        </div>
      </div>
    )
  }
}
