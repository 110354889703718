import React, { useEffect, useState } from 'react'
import styles from './Controls.module.scss'
import { InputAdornment, TextField } from '@mui/material'
import infoImg from '../../../../assets/img/info.svg'
import { PitchDimenstionsInfoCard } from '../PitchDimensions/PitchDimenstionsInfoCard'
import { Options } from '../../../Options/Options'
import { Strack, StrackOptions } from '../../Strack.types'

export interface ControlsProps {
  strack: Strack
  strackReady: boolean
  updateStrack: () => void
  strackOptions: StrackOptions
  canvasView: string
  toggleCanvasView: (view: string) => void
  live: boolean
}

export const Controls: React.FC<ControlsProps> = ({
  strackReady,
  updateStrack,
  strack,
  canvasView,
  toggleCanvasView,
  strackOptions,
  live
}) => {
  const { showSessionTracking, diags, defaultPitch, session } = strack
  const is3D = canvasView === '3D'

  const [showTracking, setShowTracking] = useState(showSessionTracking)
  const [showDebugBalls, setDebugBalls] = useState(false)
  const [showDebugLogs, setDebugLogs] = useState(false)
  const [viewAllTags, setViewAllTags] = useState(diags.viewAllTags)
  const [showTagIds, setShowTagIds] = useState(strack.showTagIds)
  const [showLowPower, setShowLowPower] = useState(strack.showLowPower)
  const [showMeas, setShowMeas] = useState(diags.showMeas)
  const [showCoords, setShowCoords] = useState(diags.showCoords)
  const [targetCoords, setTargetCoords] = useState(diags.targetCoords)
  const [toggleDimensionsInfoCard, setToggleDimensionsInfoCard] =
    useState(false)
  const [tagCalibrationModeEnabled, setTagCalibrationModeEnabled] = useState(
    diags.tagCalibrationModeEnabled
  )

  const flipPitch = (bool) => {
    strack.flipPitch(bool)
    updateStrack()
  }
  const rotatePitch = (bool) => {
    strack.rotatePitch(bool)
    updateStrack()
  }
  const toggleBench = (bool) => {
    strack.toggleBench(bool)
    updateStrack()
  }

  const generateConrolsOptions = () => {
    const options = []
    if (strackOptions.babylonActive && toggleCanvasView) {
      options.push({
        name: is3D ? '2D' : '3D',
        handle: () => toggleCanvasView(is3D ? '2D' : '3D')
      })
    }
    if (strackOptions.tracking && !strackOptions.diags && !is3D && live) {
      options.push({
        name: showTracking ? 'DISABLE TRACKING' : 'ENABLE TRACKING',
        handle: () => setShowTracking(!showTracking)
      })
    }
    if (strackOptions.diags && !is3D) {
      options.push(
        {
          name: viewAllTags ? 'ONE TAG' : 'ALL TAGS',
          handle: () => setViewAllTags(!viewAllTags)
        },
        {
          name: showMeas ? 'HIDE MEAS' : 'SHOW MEAS',
          handle: () => setShowMeas(!showMeas)
        }
      )
    }
    if (strackOptions.tracking && !is3D && (live || strackOptions.diags)) {
      options.push({
        name: showTagIds ? 'SHOW NUMBER' : 'SHOW ID',
        handle: () => setShowTagIds(!showTagIds)
      })
    }
    if (!is3D && session?.id !== 'setup') {
      if (!strack.disableFlip) {
        options.push({
          name: 'FLIP PITCH',
          handle: () => flipPitch(!strack.pitchFlipped)
        })
      }
      if (session?.sport.props.pitch.enableRotate && !strack.disableRotate) {
        options.push({
          name: 'ROTATE PITCH',
          handle: () => rotatePitch(!strack.pitchRotated)
        })
      }
      if (strack?.enableBench && live) {
        options.push({
          name: strack.showBench ? 'HIDE BENCH' : 'SHOW BENCH',
          handle: () => toggleBench(!strack.showBench)
        })
        options.push({
          name: strack.showLowPower ? 'HIDE LOW POWER' : 'SHOW LOW POWER',
          handle: () => setShowLowPower(!showLowPower)
        })
      }
    }

    if (strackOptions.tracking && !is3D && strackOptions.diags) {
      options.push({
        name: showCoords ? 'HIDE COORDINATES' : 'SHOW COORDINATES',
        handle: () => setShowCoords(!showCoords)
      })
    }

    if (strackOptions.tracking && strackOptions.diags && !is3D) {
      options.push({
        name: tagCalibrationModeEnabled
          ? 'DISABLE CALIBRATION MODE'
          : 'ENABLE CALIBRATION MODE',
        handle: () => setTagCalibrationModeEnabled(!tagCalibrationModeEnabled)
      })
    }

    if (strackOptions.tracking && !strackOptions.diags && !is3D && live) {
      options.push({
        name: showDebugBalls ? 'HIDE DEBUG POSITIONS' : 'SHOW DEBUG POSITIONS',
        handle: () => setDebugBalls(!showDebugBalls)
      })
    }
    if (strackOptions.tracking && !strackOptions.diags && !is3D && live) {
      options.push({
        name: showDebugLogs ? 'HIDE DEBUG LOGS' : 'SHOW DEBUG LOGS',
        handle: () => setDebugLogs(!showDebugLogs)
      })
    }

    return options
  }

  useEffect(() => {
    if (strackReady) {
      strack.showSessionTracking = showTracking
    }
  }, [strack, strackReady, showTracking])

  useEffect(() => {
    if (strackReady) {
      strack.showDebugBalls = showDebugBalls
    }
  }, [strack, strackReady, showDebugBalls])

  useEffect(() => {
    if (strackReady) {
      strack.showDebugLogs = showDebugLogs
    }
  }, [strack, strackReady, showDebugLogs])

  useEffect(() => {
    if (strackReady) {
      strack.showTagIds = showTagIds
    }
  }, [strack, strackReady, showTagIds])

  useEffect(() => {
    if (strackReady) {
      strack.showLowPower = showLowPower
    }
  }, [strack, strackReady, showLowPower])

  useEffect(() => {
    if (strackReady && diags) {
      diags.viewAllTags = viewAllTags
    }
  }, [diags, strackReady, viewAllTags])

  useEffect(() => {
    if (strackReady && diags) {
      diags.showMeas = showMeas
    }
  }, [diags, strackReady, showMeas])

  useEffect(() => {
    if (strackReady && diags) {
      diags.showCoords = showCoords
    }
  }, [diags, strackReady, showCoords])

  useEffect(() => {
    if (strackReady && diags) {
      diags.targetCoords = targetCoords
    }
  }, [diags, strackReady, targetCoords])

  useEffect(() => {
    if (strackReady) {
      diags.tagCalibrationModeEnabled = tagCalibrationModeEnabled
    }
  }, [diags, strackReady, tagCalibrationModeEnabled])

  return (
    <React.Fragment>
      <div className={styles.controlsOverlay}>
        <div className={styles.optionsControlsContainer}>
          <Options type='edit' options={generateConrolsOptions()} />
        </div>
        {tagCalibrationModeEnabled &&
          strackOptions.tracking &&
          !is3D &&
          strackOptions.diags && (
            <div className={styles.targetCoords}>
              <TextField
                autoComplete='chrome-off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>X: </InputAdornment>
                  )
                }}
                onChange={(e) =>
                  setTargetCoords({
                    ...targetCoords,
                    x: Number(e.target.value)
                  })
                }
                value={targetCoords.x}
                sx={{ backgroundColor: 'white', width: '150px' }}
                type='number'
                size='small'
              />
              <TextField
                autoComplete='chrome-off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>Y: </InputAdornment>
                  )
                }}
                onChange={(e) =>
                  setTargetCoords({
                    ...targetCoords,
                    y: Number(e.target.value)
                  })
                }
                value={targetCoords.y}
                sx={{ backgroundColor: 'white', width: '150px' }}
                type='number'
                size='small'
              />
              <TextField
                autoComplete='off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>Z: </InputAdornment>
                  ),
                  autoComplete: 'off'
                }}
                onChange={(e) =>
                  setTargetCoords({
                    ...targetCoords,
                    z: Number(e.target.value)
                  })
                }
                value={targetCoords.z}
                sx={{ backgroundColor: 'white', width: '150px' }}
                type='number'
                size='small'
              />
              <TextField
                autoComplete='chrome-off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>zPlot: </InputAdornment>
                  )
                }}
                onChange={(e) =>
                  setTargetCoords({
                    ...targetCoords,
                    zPlot: Number(e.target.value)
                  })
                }
                value={targetCoords.zPlot}
                sx={{
                  backgroundColor: 'white',
                  width: '150px',
                  '& .MuiTypography-root': { fontSize: '12px' }
                }}
                type='number'
                size='small'
              />
            </div>
          )}
      </div>
      {defaultPitch && (
        <div className={styles.defaultPitchLabel}>DEFAULT PITCH</div>
      )}
      {/* Dimensions Info Card */}
      {toggleDimensionsInfoCard && <PitchDimenstionsInfoCard strack={strack} />}
      <div
        onClick={() => setToggleDimensionsInfoCard(!toggleDimensionsInfoCard)}
        className={styles.pitchDimensionsInfoButton}
      >
        <img src={infoImg} alt='' />
      </div>
    </React.Fragment>
  )
}
