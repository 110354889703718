import { drawCircle } from '../2D/drawing'
import { Dimensions, Field, Poles } from '../types'
import { drawCenterDashesOnXPlane } from './canadian_football'

export function applyAmericanFootballSettings(
  field: Field,
  poles: Poles,
  dimensions: Dimensions
) {
  // Check if multi pitch - (dimensions.P43 exists) //
  const topY = dimensions.P50 ? dimensions.P50.y : dimensions.P20.y

  field.height = topY
  field.width = dimensions.P13.x - dimensions.P1.x
  field.tryLineDistance = dimensions.P12.x - dimensions.P2.x

  field.planes = {
    left: dimensions.P1.x,
    right: dimensions.P13.x,
    top: topY,
    bottom: dimensions.P7.y
  }

  field.scaledTen = field.tryLineDistance / 10
  field.edges = 3

  // Set origin to base
  field.originOffsetX = 0
  field.originOffsetY = field.height / 2

  poles.height = 13.72
  poles.width = 5.64
  poles.crossbarHeight = 3.05
  poles.diameter = 0.2

  field.color = 'transparent'

  field.goalLineWidth = dimensions.P12.x - dimensions.P2.x
}

export function drawAmericanFootballLines(
  props,
  dimensions,
  color,
  lineWidth,
  ctx,
  pixelScale,
  view,
  pitchFlipped?,
  pitchRotated?
) {
  const { field } = props

  let getCanvasCoordinate = props.getCanvasCoordinate

  if (view === '3D') {
    getCanvasCoordinate = props.get3DCanvasCoordinate
  }

  const pitchHeight = dimensions.P20.y - dimensions.P7.y

  // Draw mown patches

  if (!pitchRotated) {
    const numberOfVerticalPatches = 20

    const verticalPatchWidth = field.tryLineDistance / numberOfVerticalPatches

    const patchOrigin = dimensions.P25

    for (let i = 0; i < numberOfVerticalPatches; i++) {
      if (i % 2 === 0) {
        const scaledOrigin = getCanvasCoordinate(
          pixelScale,
          patchOrigin.x + i * verticalPatchWidth,
          patchOrigin.y,
          true
        )
        ctx.fillStyle = 'rgba(0,0,0,0.1)'
        ctx.fillRect(
          scaledOrigin.scaleX,
          scaledOrigin.scaleY,
          verticalPatchWidth * pixelScale,
          pitchHeight * pixelScale
        )
      }
    }
  } else {
    const numberOfHorizontalPatches = 20

    const horizontalPatchWidth =
      field.tryLineDistance / numberOfHorizontalPatches

    const patchOrigin = dimensions.P2

    for (let i = 0; i < numberOfHorizontalPatches; i++) {
      if (i % 2 === 0) {
        const scaledOrigin = getCanvasCoordinate(
          pixelScale,
          patchOrigin.x + i * horizontalPatchWidth,
          patchOrigin.y,
          true
        )
        ctx.fillStyle = 'rgba(0,0,0,0.1)'
        ctx.fillRect(
          scaledOrigin.scaleX,
          scaledOrigin.scaleY,
          pitchHeight * pixelScale,
          horizontalPatchWidth * pixelScale
        )
      }
    }
  }

  // Draw Borders
  ctx.beginPath()
  ctx.setLineDash([0])

  const p1Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P1.x,
    dimensions.P1.y
  )
  const p26Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P26.x,
    dimensions.P26.y
  )
  const p14Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P14.x,
    dimensions.P14.y
  )
  const p13Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P13.x,
    dimensions.P13.y
  )

  ctx.moveTo(p1Coord.scaleX, p1Coord.scaleY)
  ctx.lineTo(p26Coord.scaleX, p26Coord.scaleY)
  ctx.lineTo(p14Coord.scaleX, p14Coord.scaleY)
  ctx.lineTo(p13Coord.scaleX, p13Coord.scaleY)
  ctx.lineTo(p1Coord.scaleX, p1Coord.scaleY)

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = color
  ctx.stroke()

  const p2Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P2.x,
    dimensions.P2.y
  )
  const p25Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P25.x,
    dimensions.P25.y
  )
  const p12Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P12.x,
    dimensions.P12.y
  )
  const p15Coord = getCanvasCoordinate(
    pixelScale,
    dimensions.P15.x,
    dimensions.P15.y
  )

  // Draw trylines
  ctx.moveTo(p2Coord.scaleX, p2Coord.scaleY)
  ctx.lineTo(p25Coord.scaleX, p25Coord.scaleY)
  ctx.moveTo(p12Coord.scaleX, p12Coord.scaleY)
  ctx.lineTo(p15Coord.scaleX, p15Coord.scaleY)

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = color
  ctx.stroke()

  // Ten yard lines
  const lowerTenYardPoints = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const upperTenYardPoints = [25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15]

  ctx.beginPath()

  for (let i = 0; i < lowerTenYardPoints.length; i++) {
    const lowerPlanePoint = dimensions[`P${lowerTenYardPoints[i]}`]
    const higherPlanePoint = dimensions[`P${upperTenYardPoints[i]}`]

    const lastLowerPlanePoint = dimensions[`P${lowerTenYardPoints[i - 1]}`]
    const lastHigherPlanePoint = dimensions[`P${upperTenYardPoints[i - 1]}`]
    if (i === 0) continue

    // Draw lines between 10 yard lines
    const midX = (lowerPlanePoint.x - lastLowerPlanePoint.x) / 2

    // TODO: complete rotation for American Football - x and y values to get canvas coordinate)

    ctx.moveTo(
      getCanvasCoordinate(
        pixelScale,
        lowerPlanePoint.x - midX,
        lowerPlanePoint.y
      ).scaleX,
      getCanvasCoordinate(
        pixelScale,
        lowerPlanePoint.x - midX,
        lowerPlanePoint.y
      ).scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(
        pixelScale,
        higherPlanePoint.x - midX,
        higherPlanePoint.y
      ).scaleX,
      getCanvasCoordinate(
        pixelScale,
        higherPlanePoint.x - midX,
        higherPlanePoint.y
      ).scaleY
    )

    // Draw center dashes

    const lowerY = dimensions.P7.y

    drawCenterDashesOnXPlane(
      lowerPlanePoint.x - midX,
      ctx,
      pitchHeight,
      lowerY,
      getCanvasCoordinate,
      pixelScale
    )

    // Draw one yard line markers

    const oneYardLineSpacing = (lowerPlanePoint.x - lastLowerPlanePoint.x) / 10

    for (let j = 1; j < 10; j++) {
      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + 0
        ).scaleX,
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + 0
        ).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + 1
        ).scaleX,
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + 1
        ).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight / 2 - 6.1
        ).scaleX,
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight / 2 - 6.1
        ).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight / 2 - 6.1 - 1
        ).scaleX,
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight / 2 - 6.1 - 1
        ).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight / 2 + 6.1
        ).scaleX,
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight / 2 + 6.1
        ).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight / 2 + 6.1 + 1
        ).scaleX,
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight / 2 + 6.1 + 1
        ).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight - 1
        ).scaleX,
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight - 1
        ).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight
        ).scaleX,
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j,
          lowerY + pitchHeight
        ).scaleY
      )
    }

    if (i === lowerTenYardPoints.length - 1) continue

    // Draw 10 yard lines

    ctx.moveTo(
      getCanvasCoordinate(pixelScale, lowerPlanePoint.x, lowerPlanePoint.y)
        .scaleX,
      getCanvasCoordinate(pixelScale, lowerPlanePoint.x, lowerPlanePoint.y)
        .scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, higherPlanePoint.x, higherPlanePoint.y)
        .scaleX,
      getCanvasCoordinate(pixelScale, higherPlanePoint.x, higherPlanePoint.y)
        .scaleY
    )

    drawCenterDashesOnXPlane(
      lowerPlanePoint.x,
      ctx,
      pitchHeight,
      lowerY,
      getCanvasCoordinate,
      pixelScale
    )
  }

  ctx.lineWidth = lineWidth / 2
  ctx.strokeStyle = color
  ctx.stroke()

  ctx.beginPath()

  // Draw poles
  drawCircle(
    dimensions.P28.x,
    dimensions.P28.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P27.x,
    dimensions.P27.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )

  drawCircle(
    dimensions.P29.x,
    dimensions.P29.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P30.x,
    dimensions.P30.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
}

export const drawAmericanFootballPitchNumbers = (
  dimensions,
  getCanvasCoordinate,
  canvasPixelScale,
  ctx,
  fontSize,
  pitchRotated
) => {
  ctx.save()

  const numberCanvasCoordPoints = [
    'P24',
    'P23',
    'P22',
    'P21',
    'P20',
    'P19',
    'P18',
    'P17',
    'P16'
  ]

  const numbers = [
    '1 0',
    '2 0',
    '3 0',
    '4 0',
    '5 0',
    '4 0',
    '3 0',
    '2 0',
    '1 0'
  ]

  const upperY = dimensions.P20.y - 8 + 0.5
  const lowerY = dimensions.P7.y + 8 - 0.5

  // let upperCY = pitchHeight - 3.75
  // let lowerCY = 3.75
  ctx.font = `${fontSize * 1.5}px Clarendon`
  ctx.textAlign = 'center'
  ctx.fillStyle = 'rgba(255, 255, 255, 0.9)'
  ctx.textBaseline = 'middle'
  ctx.strokeStyle = 'rgba(255, 255, 255, 0.9)'

  const scaledUpperYCoord = getCanvasCoordinate(
    canvasPixelScale,
    dimensions.P20.x - 8 + 0.5,
    upperY
  )

  const scaledLowerYCoord = getCanvasCoordinate(
    canvasPixelScale,
    dimensions.P7.x + 8 - 0.5,
    lowerY
  )

  ctx.font = `${fontSize}px Clarendon`

  numberCanvasCoordPoints.forEach((point, i) => {
    const scaledCanvasCoord = getCanvasCoordinate(
      canvasPixelScale,
      dimensions[point].x,
      dimensions[point].y
    )

    if (!pitchRotated) {
      ctx.fillText(
        numbers[i],
        scaledCanvasCoord.scaleX,
        scaledLowerYCoord.scaleY
      )
      ctx.fillText(
        numbers[i],
        scaledCanvasCoord.scaleX,
        scaledUpperYCoord.scaleY
      )
    } else {
      // Save the current canvas context state
      ctx.save()

      // Translate context to the point where the number will be drawn
      ctx.translate(scaledLowerYCoord.scaleX, scaledCanvasCoord.scaleY)

      // Rotate the context by 90 degrees (π/2 radians)
      ctx.rotate(Math.PI / 2)

      // Draw the rotated number
      ctx.fillText(numbers[i], 0, 0)

      // Restore the canvas context to its original state
      ctx.restore()

      // Repeat for the upper coordinates
      ctx.save()
      ctx.translate(scaledUpperYCoord.scaleX, scaledCanvasCoord.scaleY)
      ctx.rotate(Math.PI / 2)
      ctx.fillText(numbers[i], 0, 0)
      ctx.restore()
    }
  })

  ctx.restore()
}
