import React, { useMemo } from 'react'
import { MainProps, Tabs } from '../../components/Views/Main/Main.types'
import {
  useFormattedSession,
  useSessions
} from '../../metrics_server/sessions/hooks'
import { PassSummary } from './PassSummary/PassSummary'
import { Coding } from './Coding/Coding'
import { Sportscaster, SportscasterWrapper } from './Sportscaster/Sportscaster'
import { Targets } from './Targets/Targets'
import { Validation } from './Validation/Validation'
import { Overview } from './Overview/Overview'

import * as TargetScreen from '../TargetSetup/config'
import { Graphics } from './Graphics/Graphics'
import { isSportType, sportTypes } from '../../metrics_server/sports/data_types'
import { CodingIcon } from '../../components/Icons/CodingIcon/CodingIcon'
import { ValidationIcon } from '../../components/Icons/ValidationIcon/ValidationIcon'
import { SummaryIcon } from '../../components/Icons/SummaryIcon/SummaryIcon'
import { SportscasterIcon } from '../../components/Icons/SportscasterIcon/SportscasterIcon'
import { GraphicsIcon } from '../../components/Icons/GraphicsIcon/GraphicsIcon'
import { TargetsIcon } from '../../components/Icons/TargetsIcon/TargetsIcon'
import { PassSummaryIcon } from '../../components/Icons/PassSummaryIcon/PassSummaryIcon'
import { MatchDashboard } from './MatchDashboard/MatchDashboard'
import { useMatchDashboardOptions } from './options'
import { DashboardIcon } from '../../components/Icons/DashboardIcon/DashboardIcon'
import { RecentEvents } from './RecentEvents/RecentEvents'
import { RecentEventsIcon } from '../../components/Icons/RecentEventsIcon/RecentEventsIcon'
import { CompetitionInsights } from './CompetitionInsights/CompetitionInsights'
import { CompetitionIcon } from '../../components/Icons/CompetitionIcon/CompetitionIcon'
import { useRouter } from '../../ui/router/hooks'
import { useVersion } from '../../metrics_server/version/hooks'
import { isLocal } from '../../metrics_server/env'
import { PlayerSummary } from './PlayerSummary/PlayerSummary'
import { PlayerSummaryIcon } from '../../components/Icons/PlayerSummaryIcon/PlayerSummaryIcon'
import { DrillsIcon } from '../../components/Icons/DrillsIcon/DrillsIcon'
import { Drills } from './Drills/Drills'

export enum MatchTrackerSessionTabs {
  CODING = 'CODING',
  VALIDATION = 'VALIDATION',
  SUMMARY = 'SUMMARY',
  GRAPHICS = 'GRAPHICS',
  SPORTSCASTER = 'SPORTSCASTER',
  CHALLENGE = 'CHALLENGE',
  PASS_SUMMARY = 'PASS_SUMMARY',
  PLAYER_SUMMARY = 'PLAYER_SUMMARY',
  DRILLS = 'DRILLS',

  INSIGHTS = 'HOME',
  MATCH_DASHBOARD = 'MATCH_DASHBOARD',
  RECENT_EVENTS = 'RECENT_EVENTS',
  COMPETITION_INSIGHTS = 'COMPETITION_INSIGHTS'
}

// Test whether the browser has hardware acceleration enabled
const hasHWA = (() => {
  // create a test function for both "default" drawing and forced software
  const test = (force = false) => {
    const canvas = new OffscreenCanvas(200, 200)
    // willReadFrequently will force software rendering
    const ctx = canvas.getContext('2d', { willReadFrequently: force })
    ctx.moveTo(0, 0)
    ctx.lineTo(120, 121) // HWA is bad at obliques
    ctx.stroke()
    return ctx.getImageData(0, 0, 200, 200).data.join()
  }
  // check that both return different results
  if (test(true) !== test(false)) {
    console.log('Hardware acceleration is enabled')
    return true
  } else {
    console.log(
      'Hardware acceleration is disabled. Please enable it in your browser settings for 3D graphics.'
    )
    return false
  }
})()

const threeDEnabled = localStorage.getItem('3D') === 'enabled'

export const sessionTabConfig: Tabs = {
  [MatchTrackerSessionTabs.CODING]: {
    key: MatchTrackerSessionTabs.CODING,
    name: 'Coding',
    Content: Coding,
    Icon: CodingIcon,
    strack: {
      options: {
        tagInfo: true,
        teams: true,
        babylonActive: false,
        canvasId: 'coding',
        cover: 'rugby-cover',
        tracking: true,
        enableBench: true,
        operatorControls: true,
        minPadding2D: 70
      },
      canvasStyle: {
        width: '65%',
        height: '100%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0
      }
    }
  },
  [MatchTrackerSessionTabs.VALIDATION]: {
    key: MatchTrackerSessionTabs.VALIDATION,
    name: 'Validation',
    Content: Validation,
    Icon: ValidationIcon,
    strack: {
      options: {
        tagInfo: true,
        teams: true,
        babylonActive: threeDEnabled,
        canvasId: 'validation',
        cover: 'rugby-cover',
        validation: true,
        tracking: true,
        touchToRefDistance: true,
        minPadding2D: 70
      },
      canvasStyle: {
        width: '65%',
        height: '72%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '0%',
        left: '0%',
        overflow: 'hidden'
      }
    }
  },
  [MatchTrackerSessionTabs.SUMMARY]: {
    key: MatchTrackerSessionTabs.SUMMARY,
    name: 'Summary',
    Content: Overview,
    Icon: SummaryIcon,
    strack: {
      options: {
        tagInfo: true,
        eventLabels: true,
        teams: true,
        babylonActive: threeDEnabled,
        canvasId: 'overview',
        cover: 'rugby-cover',
        show2DOnly: false,
        validation: true,
        tracking: true,
        eventsClickable: true,
        minPadding2D: 70
      },
      canvasStyle: {
        width: '65%',
        height: '100%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        bottom: 0,
        left: 0
      }
    }
  },
  [MatchTrackerSessionTabs.SPORTSCASTER]: {
    key: MatchTrackerSessionTabs.SPORTSCASTER,
    name: 'Sportscaster',
    Content: SportscasterWrapper,
    Icon: SportscasterIcon,
    strack: {
      options: {
        tagInfo: true,
        teams: true,
        babylonActive: threeDEnabled,
        canvasId: 'sportscaster',
        cover: 'rugby-cover',
        tracking: true,
        customTrackingSource: true,
        broadcastIntegrationEnabled: true,
        minPadding2D: 70
      },
      canvasStyle: {
        width: '65%',
        height: '72%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '0%',
        left: '0%',
        overflow: 'hidden'
      }
    }
  },
  [MatchTrackerSessionTabs.GRAPHICS]: {
    key: MatchTrackerSessionTabs.GRAPHICS,
    name: 'Graphics',
    Content: Graphics,
    Icon: GraphicsIcon
  },
  [MatchTrackerSessionTabs.PASS_SUMMARY]: {
    key: MatchTrackerSessionTabs.PASS_SUMMARY,
    name: 'Pass Summary',
    Content: PassSummary,
    Icon: PassSummaryIcon
  },

  // [MatchTrackerSessionTabs.PLAYER_SUMMARY]: {
  //   key: MatchTrackerSessionTabs.PLAYER_SUMMARY,
  //   name: 'Player Summary',
  //   Content: PlayerSummary,
  //   Icon: PlayerSummaryIcon
  // },
  [MatchTrackerSessionTabs.DRILLS]: {
    key: MatchTrackerSessionTabs.DRILLS,
    name: 'Drills',
    Content: Drills,
    Icon: DrillsIcon,
    strack: {
      options: {
        // tagInfo: true,
        // babylonActive: hasHWA,
        // validation: true,
        // touchToRefDistance: true
        teams: true,
        drillRegions: true,
        canvasId: 'drills',
        cover: 'rugby-cover',
        // disableRotate: true,
        // disableFlip: true,
        tracking: true
      },
      canvasStyle: {
        width: '60%',
        height: '73%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '27%',
        left: 0,
        overflow: 'hidden'
      }
    }
  },
  [MatchTrackerSessionTabs.CHALLENGE]: {
    key: MatchTrackerSessionTabs.CHALLENGE,
    name: 'Targets',
    Content: Targets,
    Icon: TargetsIcon,
    strack: {
      options: {
        teams: true,
        targets: true,
        babylonActive: threeDEnabled,
        canvasId: 'challenge',
        cover: 'rugby-cover',
        minPadding2D: 70
      },
      canvasStyle: {
        // TODO add is tablet back in
        width: '60%',
        height: 'calc(100% - 50px)',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '50px',
        left: '0%',
        overflow: 'hidden'
      }
    }
  },

  [MatchTrackerSessionTabs.MATCH_DASHBOARD]: {
    key: MatchTrackerSessionTabs.MATCH_DASHBOARD,
    name: 'Match Dashboard',
    Content: MatchDashboard,
    useOptions: useMatchDashboardOptions,
    Icon: DashboardIcon
  },
  [MatchTrackerSessionTabs.RECENT_EVENTS]: {
    key: MatchTrackerSessionTabs.RECENT_EVENTS,
    name: 'Recent Events',
    Content: RecentEvents,
    Icon: RecentEventsIcon,
    strack: {
      options: {
        teams: true,
        tagInfo: true,
        babylonActive: false,
        canvasId: 'recentEvents',
        cover: 'rugby-cover',
        validation: true,
        tracking: true,
        minPadding2D: 70
      },
      canvasStyle: {
        width: '65%',
        height: '65%',
        padding: '10px 10px 10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        left: 0
      }
    }
  },
  [MatchTrackerSessionTabs.COMPETITION_INSIGHTS]: {
    key: MatchTrackerSessionTabs.COMPETITION_INSIGHTS,
    name: 'Competition Insight',
    Content: CompetitionInsights,
    Icon: CompetitionIcon
  }
}

export const useSessionTabConfig = (view: MainProps) => {
  const router = useRouter()
  const { selectedId } = useSessions()
  const { uiType } = useVersion()
  const { live, isTrainingMode, isMatchMode, sport } =
    useFormattedSession(selectedId)
  return useMemo(() => {
    const tabs = { ...view.tabs }
    let initialTab

    // TODO: Each session tab should be a feature flag
    //
    // MatchTracker Session View
    //
    // Rugby Union
    //    Match:
    //      - Coding
    //      - Validation
    //      - Summary
    //      - Graphics
    //      - Sportscaster
    //    Training:
    //      - Validation
    //      - Summary
    //      - Targets
    // Canadian Football
    //    Match:
    //      - Coding
    //      - Validation
    //      - Summary
    //      - Sportscaster
    //    Training:
    //      - Validation
    //      - Summary
    //      - Targets
    // American Football
    //    Training:
    //      - Validation
    //      - Summary
    //      - Targets
    //      - Pass Summary
    // Soccer
    //    Training:
    //      - Validation
    //      - Summary
    //      - Targets
    //      - Head-to-Head (new)
    // AFL
    //    Training:
    //      - Validation
    //      - Summary
    //      - Targets

    if (uiType.value === 'commentatorTool') {
      initialTab = tabs[MatchTrackerSessionTabs.RECENT_EVENTS]

      delete tabs[MatchTrackerSessionTabs.GRAPHICS]
      delete tabs[MatchTrackerSessionTabs.CODING]
      delete tabs[MatchTrackerSessionTabs.VALIDATION]
      delete tabs[MatchTrackerSessionTabs.CHALLENGE]
      delete tabs[MatchTrackerSessionTabs.PASS_SUMMARY]
      delete tabs[MatchTrackerSessionTabs.SPORTSCASTER]
      delete tabs[MatchTrackerSessionTabs.SUMMARY]

      delete tabs[MatchTrackerSessionTabs.MATCH_DASHBOARD]
      delete tabs[MatchTrackerSessionTabs.COMPETITION_INSIGHTS]
    } else {
      console.log(router.prevRoute, TargetScreen.path)
      const lastRouteIsTargets = router.prevRoute === TargetScreen.path

      delete tabs[MatchTrackerSessionTabs.MATCH_DASHBOARD]
      delete tabs[MatchTrackerSessionTabs.RECENT_EVENTS]
      delete tabs[MatchTrackerSessionTabs.COMPETITION_INSIGHTS]

      if (lastRouteIsTargets) {
        initialTab = tabs[MatchTrackerSessionTabs.CHALLENGE]
      } else if (isTrainingMode) {
        initialTab = tabs[MatchTrackerSessionTabs.VALIDATION]
      } else {
        initialTab = isTrainingMode
          ? tabs[MatchTrackerSessionTabs.VALIDATION]
          : tabs[MatchTrackerSessionTabs.CODING]
      }

      if (isLocal) {
        delete tabs[MatchTrackerSessionTabs.GRAPHICS]
      }

      if (sport.value !== sportTypes.items.americanFootball.value) {
        delete tabs[MatchTrackerSessionTabs.PASS_SUMMARY]
      }

      // TODO: Removing Player Summary for now - until BE is ready //
      // if (sport.value !== sportTypes.items.soccer.value) {
      delete tabs[MatchTrackerSessionTabs.PLAYER_SUMMARY]

      if (isTrainingMode) {
        tabs[MatchTrackerSessionTabs.VALIDATION].name = 'Detail'
        tabs[MatchTrackerSessionTabs.SUMMARY].name = 'Overview'
        delete tabs[MatchTrackerSessionTabs.GRAPHICS]
        delete tabs[MatchTrackerSessionTabs.CODING]
      } else {
        tabs[MatchTrackerSessionTabs.VALIDATION].name = 'Validation'
        tabs[MatchTrackerSessionTabs.SUMMARY].name = 'Summary'
        delete tabs[MatchTrackerSessionTabs.DRILLS]
      }

      if (!live) {
        delete tabs[MatchTrackerSessionTabs.SPORTSCASTER]
      }

      // Remove targets if CFL match
      if (isSportType.canadianFootball(sport) && isMatchMode) {
        delete tabs[MatchTrackerSessionTabs.CHALLENGE]
      }

      if (!sport.props.features.drillsEnabled) {
        delete tabs[MatchTrackerSessionTabs.DRILLS]
      }
    }
    return { tabs, initialTab }
  }, [live, isTrainingMode, isMatchMode, sport, router, uiType, view])
}
