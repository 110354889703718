import { SportTypeKey } from '../data_types'
import { sportTypeKey } from '../'

import {
  Coordinate,
  Pitch,
  PitchCoordinates,
  PitchDimensions
} from '../../pitches/types'

export interface AmericanFootballPitch extends Pitch {
  type: SportTypeKey['americanFootball']
  pitch: AmericanFootballPitchDimensions
}

export interface AmericanFootballPitchDimensions extends PitchDimensions {
  length: number
  width: number
  poles: {
    width: number
    height: number
    crossbarHeight: number
  }
  coordinates: AmericanFootballPitchCoordinates
}

export interface AmericanFootballPitchCoordinates extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
  P5: Coordinate
  P6: Coordinate
  P7: Coordinate
  P8: Coordinate
  P9: Coordinate
  P10: Coordinate
  P11: Coordinate
  P12: Coordinate
  P13: Coordinate
  P14: Coordinate
  P15: Coordinate
  P16: Coordinate
  P17: Coordinate
  P18: Coordinate
  P19: Coordinate
  P20: Coordinate
  P21: Coordinate
  P22: Coordinate
  P23: Coordinate
  P24: Coordinate
  P25: Coordinate
  P26: Coordinate
  P27: Coordinate
  P28: Coordinate
  P29: Coordinate
  P30: Coordinate
}

const coordinates = {
  P1: {
    x: -60.0,
    y: 0.0,
    z: 0.0
  },
  P2: {
    x: -50.0,
    y: 0.0,
    z: 0.0
  },
  P3: {
    x: -40.0,
    y: 0.0,
    z: 0.0
  },
  P4: {
    x: -30,
    y: 0.0,
    z: 0.0
  },
  P5: {
    x: -20.0,
    y: 0.0,
    z: 0.0
  },
  P6: {
    x: -10.0,
    y: 0.0,
    z: 0.0
  },
  P7: {
    x: 0.0,
    y: 0.0,
    z: 0.0
  },
  P8: {
    x: 10,
    y: 0.0,
    z: 0.0
  },
  P9: {
    x: 20,
    y: 0.0,
    z: 0.0
  },
  P10: {
    x: 30,
    y: 0.0,
    z: 0.0
  },
  P11: {
    x: 40,
    y: 0.0,
    z: 0.0
  },
  P12: {
    x: 50,
    y: 0.0,
    z: 0.0
  },
  P13: {
    x: 60,
    y: 0.0,
    z: 0.0
  },
  P14: {
    x: 60,
    y: 68,
    z: 0.0
  },
  P15: {
    x: 50,
    y: 68,
    z: 0.0
  },
  P16: {
    x: 40,
    y: 68,
    z: 0.0
  },
  P17: {
    x: 30,
    y: 68,
    z: 0.0
  },
  P18: {
    x: 20,
    y: 68,
    z: 0.0
  },
  P19: {
    x: 10,
    y: 68,
    z: 0.0
  },
  P20: {
    x: 0.0,
    y: 68,
    z: 0.0
  },
  P21: {
    x: -10,
    y: 68,
    z: 0.0
  },
  P22: {
    x: -20,
    y: 68,
    z: 0.0
  },
  P23: {
    x: -30,
    y: 68,
    z: 0.0
  },
  P24: {
    x: -40,
    y: 68,
    z: 0.0
  },
  P25: {
    x: -50,
    y: 68,
    z: 0.0
  },
  P26: {
    x: -60,
    y: 68,
    z: 0.0
  },
  P27: {
    x: -60,
    y: 31.18,
    z: 0.0
  },
  P28: {
    x: -60,
    y: 36.82,
    z: 0.0
  },
  P29: {
    x: 60,
    y: 31.18,
    z: 0.0
  },
  P30: {
    x: 60,
    y: 36.82,
    z: 0.0
  }
}

const doublePitchCoordinates = {
  P1: {
    x: -54.864,
    y: 0,
    z: 0
  },
  P2: {
    x: -45.72,
    y: 0,
    z: 0
  },
  P3: {
    x: -36.576,
    y: 0,
    z: 0
  },
  P4: {
    x: -27.432,
    y: 0,
    z: 0
  },
  P5: {
    x: -18.288,
    y: 0,
    z: 0
  },
  P6: {
    x: -9.144,
    y: 0,
    z: 0
  },
  P7: {
    x: 0,
    y: 0,
    z: 0
  },
  P8: {
    x: 9.144,
    y: 0,
    z: 0
  },
  P9: {
    x: 18.288,
    y: 0,
    z: 0
  },
  P10: {
    x: 27.432,
    y: 0,
    z: 0
  },
  P11: {
    x: 36.576,
    y: 0,
    z: 0
  },
  P12: {
    x: 45.72,
    y: 0,
    z: 0
  },
  P13: {
    x: 54.864,
    y: 0,
    z: 0
  },
  P14: {
    x: 54.864,
    y: 48.768,
    z: 0
  },
  P15: {
    x: 45.72,
    y: 48.768,
    z: 0
  },
  P16: {
    x: 36.576,
    y: 48.768,
    z: 0
  },
  P17: {
    x: 27.432,
    y: 48.768,
    z: 0
  },
  P18: {
    x: 18.288,
    y: 48.768,
    z: 0
  },
  P19: {
    x: 9.144,
    y: 48.768,
    z: 0
  },
  P20: {
    x: 0,
    y: 48.768,
    z: 0
  },
  P21: {
    x: -9.144,
    y: 48.768,
    z: 0
  },
  P22: {
    x: -18.288,
    y: 48.768,
    z: 0
  },
  P23: {
    x: -27.432,
    y: 48.768,
    z: 0
  },
  P24: {
    x: -36.576,
    y: 48.768,
    z: 0
  },
  P25: {
    x: -45.72,
    y: 48.768,
    z: 0
  },
  P26: {
    x: -54.864,
    y: 48.768,
    z: 0
  },
  P27: {
    x: -54.864,
    y: 21.5646,
    z: 3.048
  },
  P28: {
    x: -54.864,
    y: 27.2034,
    z: 3.048
  },
  P29: {
    x: 54.864,
    y: 27.2034,
    z: 3.048
  },
  P30: {
    x: 54.864,
    y: 21.5646,
    z: 3.048
  },
  P31: {
    x: -54.864,
    y: 58.768001556396484,
    z: 0
  },
  P32: {
    x: -45.72,
    y: 58.768001556396484,
    z: 0
  },
  P33: {
    x: -36.576,
    y: 58.768001556396484,
    z: 0
  },
  P34: {
    x: -27.432,
    y: 58.768001556396484,
    z: 0
  },
  P35: {
    x: -18.288,
    y: 58.768001556396484,
    z: 0
  },
  P36: {
    x: -9.144,
    y: 58.768001556396484,
    z: 0
  },
  P37: {
    x: 0,
    y: 58.768001556396484,
    z: 0
  },
  P38: {
    x: 9.144,
    y: 58.768001556396484,
    z: 0
  },
  P39: {
    x: 18.288,
    y: 58.768001556396484,
    z: 0
  },
  P40: {
    x: 27.432,
    y: 58.768001556396484,
    z: 0
  },
  P41: {
    x: 36.576,
    y: 58.768001556396484,
    z: 0
  },
  P42: {
    x: 45.72,
    y: 58.768001556396484,
    z: 0
  },
  P43: {
    x: 54.864,
    y: 58.768001556396484,
    z: 0
  },
  P44: {
    x: 54.864,
    y: 107.53600155639649,
    z: 0
  },
  P45: {
    x: 45.72,
    y: 107.53600155639649,
    z: 0
  },
  P46: {
    x: 36.576,
    y: 107.53600155639649,
    z: 0
  },
  P47: {
    x: 27.432,
    y: 107.53600155639649,
    z: 0
  },
  P48: {
    x: 18.288,
    y: 107.53600155639649,
    z: 0
  },
  P49: {
    x: 9.144,
    y: 107.53600155639649,
    z: 0
  },
  P50: {
    x: 0,
    y: 107.53600155639649,
    z: 0
  },
  P51: {
    x: -9.144,
    y: 107.53600155639649,
    z: 0
  },
  P52: {
    x: -18.288,
    y: 107.53600155639649,
    z: 0
  },
  P53: {
    x: -27.432,
    y: 107.53600155639649,
    z: 0
  },
  P54: {
    x: -36.576,
    y: 107.53600155639649,
    z: 0
  },
  P55: {
    x: -45.72,
    y: 107.53600155639649,
    z: 0
  },
  P56: {
    x: -54.864,
    y: 107.53600155639649,
    z: 0
  },
  P57: {
    x: -54.864,
    y: 80.33260155639648,
    z: 3.048
  },
  P58: {
    x: -54.864,
    y: 85.97140155639649,
    z: 3.048
  },
  P59: {
    x: 54.864,
    y: 85.97140155639649,
    z: 3.048
  },
  P60: {
    x: 54.864,
    y: 80.33260155639648,
    z: 3.048
  }
}

export const defaultPitch: AmericanFootballPitch = {
  id: '0',
  name: 'Default American Football Pitch',
  type: sportTypeKey.americanFootball,
  anchors: null,
  arcs: null,
  pitch: {
    length: 0,
    width: 0,
    poles: {
      width: 0,
      height: 0,
      crossbarHeight: 0
    },
    coordinates: doublePitchCoordinates
  }
}
