import { useEffect, useRef, useState } from 'react'
import moment, { getDurationFromStartAndEndTime } from '.'

export const useActiveMomentDuration = (
  startTime: number,
  endTime: number,
  unit: moment.unitOfTime.DurationConstructor = 'seconds'
) => {
  const [duration, setDuration] = useState<moment.Duration>(
    moment.duration(0, unit)
  )

  const intervalRef = useRef(null)

  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current)
    intervalRef.current = setInterval(() => {
      setDuration(getDurationFromStartAndEndTime(startTime, endTime, unit))
    }, 1000)

    return () => clearInterval(intervalRef.current)
  }, [startTime, endTime, unit])

  return duration
}

export const useFormattedDuration = (startTime: number, endTime: number) => {
  const duration = useActiveMomentDuration(startTime, endTime)
  return {
    ...duration,
    formatted: `${String(duration.hours()).padStart(2, '0')}:${String(
      duration.minutes()
    ).padStart(2, '0')}:${String(duration.seconds()).padStart(2, '0')}`
  }
}
