import ReactTooltip from 'react-tooltip'
import { formatDrillSessionDuration } from '../../../../metrics_server/drills/functions'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'
import styles from './DrillTimeLine.module.scss'
import { sportableColors } from '../../../../constants/sportableColors'
import { useEffect, useMemo, useState } from 'react'
import { selectDrill } from '../../slice'
import { useAppDispatch } from '../../../../store/hooks'
import { useActiveFormattedDrill, useDrills, useDrillsGroup } from '../../hooks'
import { milToFormat } from '../../../../utils/helpers'
import {
  useActiveMomentDuration,
  useFormattedDuration
} from '../../../../utils/moment/hooks'
import { FormattedSession } from '../../../sessions/types'

// Function to calculate and update progress
export const updateDrillTimeLineProgressBar = (
  hours: number,
  formattedSession: FormattedSession
) => {
  const now = new Date().getTime()

  const milliseconds = hours * 60 * 60 * 1000

  const sessionDurationInMilliseconds = !isNaN(formattedSession.endTime.unixMil)
    ? formattedSession.endTime.unixMil - formattedSession.startTime.unixMil
    : now - formattedSession.startTime.unixMil

  const progressPercentage =
    (sessionDurationInMilliseconds / milliseconds) * 100

  return {
    progress: Math.min(100, Math.max(0, progressPercentage)),
    progressColor: formattedSession.endTime?.unixSeconds ? 'black' : 'red'
  }
}

export const DrillTimeLine = () => {
  const dispatch = useAppDispatch()

  const activeFormattedDrill = useActiveFormattedDrill()
  const { selectedId } = useDrills()

  const formattedSession = useSelectedFormattedSession()
  const { startTime, endTime, live } = formattedSession

  const [progress, setProgress] = useState<number | null>(0) // Default to 0
  const [progressColor, setProgressColor] = useState<string>('black')

  const sessionDuration = useActiveMomentDuration(
    formattedSession.startTime.unixSeconds,
    formattedSession.endTime?.unixSeconds
  )

  const hours = useMemo(() => {
    return Math.ceil(sessionDuration.asHours()) || 1 // Default to 1 hour
  }, [sessionDuration])

  useEffect(() => {
    const interval = setInterval(() => {
      const { progress, progressColor } = updateDrillTimeLineProgressBar(
        hours,
        formattedSession
      )
      setProgress(progress)
      setProgressColor(progressColor)
    }, 1000)

    const { progress, progressColor } = updateDrillTimeLineProgressBar(
      hours,
      formattedSession
    )
    setProgress(progress)
    setProgressColor(progressColor)

    return () => clearInterval(interval)
  }, [hours, sessionDuration, formattedSession])

  const [elapsedTime, setElapsedTime] = useState(0)

  useEffect(() => {
    if (live) {
      const interval = setInterval(() => {
        const now = new Date().getTime() / 1000
        setElapsedTime(now - (startTime?.unixSeconds || 0))
      }, 1000)
      return () => clearInterval(interval)
    } else {
      if (endTime && startTime) {
        setElapsedTime(endTime.unixSeconds - startTime.unixSeconds)
      }
    }
  }, [live, endTime, startTime])

  const formattedElapsedTime = formatDrillSessionDuration(elapsedTime)

  let timeMarkerCountPerHour = 4
  let timeMarkerIncrement = 15
  if (hours >= 4) {
    timeMarkerCountPerHour = 2
    timeMarkerIncrement = 30
  }
  if (hours >= 8) {
    timeMarkerCountPerHour = 1
    timeMarkerIncrement = 60
  }

  const timeMarkersIndexArray = [
    ...(Array(timeMarkerCountPerHour * hours).keys() || [])
  ]

  const drillsGroup = useDrillsGroup()

  const activeDrillDuration = useFormattedDuration(
    activeFormattedDrill?.startTime.unixSeconds,
    null
  )

  const filtererdDrills = useMemo(() => {
    return drillsGroup.list.filter((drill) => {
      if (drill.isPending) return false
      return true
    })
  }, [drillsGroup.list])

  return (
    <div className={styles.outerWrapper}>
      {timeMarkersIndexArray.map((index) => {
        const hour = Math.floor(index / timeMarkerCountPerHour)
        const minutes = (index % timeMarkerCountPerHour) * timeMarkerIncrement
        const timeString = `${hour}h${minutes > 0 ? ` ${minutes}m` : ''}`

        return (
          <div
            key={`marker-${index}`}
            style={{
              position: 'absolute',
              left: `${(index / (hours * timeMarkerCountPerHour)) * 100}%`,
              transform: 'translateX(-50%)',
              zIndex: 2,
              top: '-18px',
              backgroundColor: '#f0f0f0',
              color: '#333',
              borderRadius: '12px',
              padding: '2px 8px',
              fontSize: '12px',
              fontWeight: '500',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          >
            {timeString}
          </div>
        )
      })}
      <div
        className={styles.timeLineContainer}
        data-testid='timeline-container'
      >
        <div
          style={{
            backgroundColor: sportableColors.colors.sportableGrey,
            height: '100%',
            position: 'relative',
            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
          }}
        >
          {timeMarkersIndexArray.map((index) => {
            return (
              <div
                key={`grid-${index}`}
                style={{
                  position: 'absolute',
                  left: `${(index / (hours * timeMarkerCountPerHour)) * 100}%`,
                  top: '0',
                  bottom: '0',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
                  zIndex: 1
                }}
              ></div>
            )
          })}
          {progress !== null && (
            <div
              data-testid='progress-bar'
              style={{
                position: 'absolute',
                left: live ? `${progress + 0.1}%` : `${progress + 1}%`,
                top: '0',
                bottom: '0',
                width: '1px',
                backgroundColor: progressColor,
                zIndex: 3,
                pointerEvents: 'none'
              }}
            ></div>
          )}
          {filtererdDrills
            .filter((drill) => {
              if (drill.isPending) return false
              return true
            })
            .map((drill, index) => {
              const startPercentage =
                (drill.sessionStartTime / (hours * 60 * 60)) * 100
              const endPercentage = drill.sessionEndTime
                ? (drill.sessionEndTime / (hours * 60 * 60)) * 100
                : progress

              const widthPercentage = Math.max(
                endPercentage - startPercentage,
                1
              )
              const gap = 4

              return (
                <div key={drill.id}>
                  <div
                    data-tip
                    data-for={drill.id}
                    onClick={() => {
                      dispatch(selectDrill(drill.id))
                    }}
                    style={{
                      position: 'absolute',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      overflow: 'hidden',
                      height: `25px`,
                      left: `${startPercentage}%`,
                      width: `${widthPercentage}%`,
                      top: `${index * (25 + gap)}px`,
                      backgroundColor:
                        selectedId === drill.id
                          ? sportableColors.colors.sportableBlueLight
                          : sportableColors.colors.sportableGreyLight,
                      borderRadius: `2px`,
                      boxShadow: `0 2px 4px rgba(0,0,0,0.1)`,
                      cursor: 'pointer',
                      paddingLeft: '4px',
                      boxSizing: 'border-box',
                      margin: '1px'
                    }}
                  >
                    <div
                      className={styles.verticalBanner}
                      style={{
                        backgroundColor: drill.color
                      }}
                    ></div>
                    <span className={styles.drillSegmentTitle}>
                      {widthPercentage > 6 &&
                        `${drill.name} - ${
                          drill.duration > 0
                            ? milToFormat(drill.duration * 1000, {
                                colon: true
                              })
                            : activeDrillDuration.formatted
                        }`}
                    </span>
                  </div>
                  {widthPercentage <= 6 && (
                    <ReactTooltip id={drill.id} aria-haspopup='true'>
                      <p>
                        {drill.name} -{' '}
                        {`${
                          drill.duration > 0
                            ? milToFormat(drill.duration * 1000, {
                                colon: true
                              })
                            : activeDrillDuration.formatted
                        }`}
                      </p>
                    </ReactTooltip>
                  )}
                </div>
              )
            })}
        </div>
      </div>
      <div className={styles.sessionDuration} data-testid='session-duration'>
        {formattedElapsedTime}
      </div>
    </div>
  )
}
